const DislikeIconSVG = ({
  width,
  height,
}: {
  width?: number | string;
  height?: number | string;
}) => {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9834 15.8751L9.56673 17.8751C9.90006 18.2084 10.6501 18.3751 11.1501 18.3751H14.3167C15.3167 18.3751 16.4001 17.6251 16.6501 16.6251L18.6501 10.5417C19.0667 9.37505 18.3167 8.37505 17.0667 8.37505H13.7334C13.2334 8.37505 12.8167 7.95839 12.9001 7.37505L13.3167 4.70839C13.4834 3.95839 12.9834 3.12505 12.2334 2.87505C11.5667 2.62505 10.7334 2.95839 10.4001 3.45839L6.9834 8.54172"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M2.7334 15.875V7.70833C2.7334 6.54167 3.2334 6.125 4.40007 6.125H5.2334C6.40006 6.125 6.90007 6.54167 6.90007 7.70833V15.875C6.90007 17.0417 6.40006 17.4583 5.2334 17.4583H4.40007C3.2334 17.4583 2.7334 17.0417 2.7334 15.875Z"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DislikeIconSVG;
