const ChefIconSVG = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1797_8932)">
        <circle cx="4.74984" cy="4.08333" r="3.33333" fill="#073938" />
        <path
          d="M3.75062 2.68312C3.52077 2.68297 3.29789 2.762 3.11948 2.90693C2.94108 3.05185 2.81804 3.25381 2.77109 3.47881C2.72413 3.70381 2.75613 3.93812 2.86168 4.1423C2.96724 4.34648 3.13991 4.50807 3.35064 4.59986V5.13304H6.15055V4.59986C6.3728 4.50287 6.55225 4.32844 6.65549 4.10902C6.75874 3.88961 6.77876 3.64016 6.71182 3.40709C6.64489 3.17401 6.49555 2.9732 6.29161 2.84201C6.08767 2.71082 5.84301 2.65819 5.60316 2.69392C5.54269 2.51579 5.42793 2.36107 5.27502 2.2515C5.1221 2.14193 4.93871 2.08301 4.75059 2.08301C4.56247 2.08301 4.37908 2.14193 4.22616 2.2515C4.07325 2.36107 3.9585 2.51579 3.89802 2.69392C3.84922 2.68672 3.79995 2.68311 3.75062 2.68312ZM6.15015 5.43303H3.35104C3.35304 5.71242 3.36724 5.86541 3.46783 5.96581C3.58503 6.08301 3.77342 6.08301 4.15061 6.08301H5.35057C5.72776 6.08301 5.91615 6.08301 6.03335 5.96581C6.13395 5.86541 6.14815 5.71242 6.15015 5.43303Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1797_8932">
          <rect width="8" height="8" fill="white" transform="translate(0.75 0.0830078)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChefIconSVG;
