const LikeIconSVG = ({ width, height }: { width?: number | string; height?: number | string }) => {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9917 15.9081V7.44144C6.9917 7.10811 7.0917 6.78311 7.27503 6.50811L9.55003 3.12478C9.90837 2.58311 10.8 2.19978 11.5584 2.48311C12.375 2.75811 12.9167 3.67478 12.7417 4.49144L12.3084 7.21644C12.275 7.46644 12.3417 7.69144 12.4834 7.86644C12.625 8.02478 12.8334 8.12478 13.0584 8.12478H16.4834C17.1417 8.12478 17.7084 8.39144 18.0417 8.85811C18.3584 9.30811 18.4167 9.89144 18.2084 10.4831L16.1584 16.7248C15.9 17.7581 14.775 18.5998 13.6584 18.5998H10.4084C9.85003 18.5998 9.0667 18.4081 8.70837 18.0498L7.6417 17.2248C7.23337 16.9164 6.9917 16.4248 6.9917 15.9081Z"
        fill="#073938"
      />
      <path
        d="M4.3415 5.81689H3.48317C2.1915 5.81689 1.6665 6.31689 1.6665 7.55023V15.9336C1.6665 17.1669 2.1915 17.6669 3.48317 17.6669H4.3415C5.63317 17.6669 6.15817 17.1669 6.15817 15.9336V7.55023C6.15817 6.31689 5.63317 5.81689 4.3415 5.81689Z"
        fill="#073938"
      />
    </svg>
  );
};

export default LikeIconSVG;
