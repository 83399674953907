import { FC, memo, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/system';
import {
  Typography,
  ButtonGroup,
  IconButton,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Alert,
  Snackbar,
  Box,
  Stack,
  Button,
  Dialog,
  Grid,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ModalTransition from '../ModalTransition';
import { authState, signUpSignInModalState } from '@atoms/auth';
import { profileState } from '@atoms/profile';
import { useRecoilValue, useRecoilState } from 'recoil';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
// COMPONENTS
import Modal from '@components/Modal';
import TextMaxLine from '@components/TextMaxLine';
import DotMenuIconSVG from '../svg/DotMenuIcon';
import LinkIconSVG from '../svg/LinkIcon';
import WarningMessage from '@components/course/WarningMessage';
// SERVICES
import { get_access_token } from '@services/auth';
import { delete_review, complain_review } from 'src/services/review';

const Manage = styled('div')({
  marginRight: '4px',
  zIndex: 1,
  '.MuiPaper-root': {
    marginTop: '12px',
    marginRight: '4px',
    boxShadow:
      '0px 20px 40px -4px rgba(145, 158, 171, 0.24), 0px 0px 2px 0px rgba(145, 158, 171, 0.24)',
    a: {
      textDecoration: 'none',
      color: '#212B36',
    },
  },
});

interface ManageProps {
  reviewId?: number;
  bookingId?: number;
  isAuthor: boolean;
  shareUrl: string;
  isPinned?: boolean;
  isCourseOwner?: boolean;
  action?: any;
}

interface reportForm {
  name: string;
  email: string;
  reason: string;
}

const reportSchema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().required(),
    reason: yup.string().required('This field is required. Please tell us why'),
  })
  .required();

const ReviewManage: FC<ManageProps> = ({
  reviewId,
  bookingId,
  isAuthor,
  shareUrl,
  isPinned,
  isCourseOwner,
  action,
}) => {
  const router = useRouter();
  const shareModalImage =
    'https://images.potioneer.com/vector/share-modal_ecgZX9a4e.png?updatedAt=1688925111333';

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const [isOpenModalShare, setIsOpenModalShare] = useState<boolean>(false);
  const [isOpenCopyLinkAlert, setIsOpenCopyLinkAlert] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenDeleteAlert, setIsOpenDeleteAlert] = useState<boolean>(false);
  const [isOpenReportModal, setIsOpenReportModal] = useState<boolean>(false);
  const [isOpenReportAlertInfo, setIsOpenReportAlertInfo] = useState<boolean>(false);
  const [isOpenReportAlertErr, setIsOpenReportAlertErr] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isOpenAuthModal, setIsOpenAuthModal] = useRecoilState(signUpSignInModalState);
  const auth = useRecoilValue(authState);
  const { email, firstName, lastName } = useRecoilValue(profileState);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<reportForm>({
    resolver: yupResolver(reportSchema),
    defaultValues: {
      email: email,
      name: `${firstName} ${lastName}`,
      reason: '',
    },
  });

  useEffect(() => {
    if (!isOpenReportModal) {
      reset();
    }
  }, [isOpenReportModal, reset]);

  useEffect(() => {
    if (open) {
      setValue('reason', '');
      setValue('name', `${firstName} ${lastName}`);
      setValue('email', email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, email, firstName, lastName]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const onDelete = () => {
    if (!reviewId) return;

    get_access_token().then((token) => {
      delete_review(reviewId, token)
        .then((res) => {
          if (res?.code !== '200') {
            return;
          }

          setIsOpenDeleteAlert(true);
          setIsOpenDeleteModal(false);
          setIsLoading(false);

          if (!action && !action?.fetchReviewData) {
            return;
          } else {
            action.fetchReviewData(reviewId || 0);
          }
        })
        .catch(() => {
          setIsOpenDeleteModal(false);
          setIsLoading(false);
        });
    });
  };

  const onReport = (data: reportForm) => {
    if (!auth.auth && !auth.loading) {
      setIsOpenAuthModal({ open: true, mode: 'sign-in' });
      return;
    }

    if (!reviewId) return;
    setIsLoading(true);
    get_access_token().then((token) => {
      complain_review(reviewId, token, data.reason)
        .then((res) => {
          if (res?.code !== '200') {
            return;
          }

          if (action && action?.setReportAlert) {
            action.setReportAlert(1);
          }
          setIsLoading(false);
          setIsOpenReportModal(false);
        })
        .catch(() => {
          if (action && action?.setReportAlert) {
            action.setReportAlert(2);
          }
          setIsLoading(false);
        });
    });
  };

  // BUTTON COMPONENTS
  const ShareButton = () => {
    return (
      <MenuItem
        onClick={() => {
          setIsOpenModalShare(true);
        }}
      >
        Share
      </MenuItem>
    );
  };

  const PinButton = () => {
    return (
      <MenuItem
        onClick={() => {
          if (!action || !action?.checkPinReview) {
            setOpen(false);
            return;
          } else {
            action.checkPinReview();
            setOpen(false);
          }
        }}
      >
        {isPinned ? 'Unpin from top' : 'Pin to top'}
        <img
          src={isPinned ? '/icons/ic_unpin.svg' : '/icons/ic_pin_top.svg'}
          alt={isPinned ? 'unpin' : 'pin'}
          style={{ marginLeft: 8 }}
        />
      </MenuItem>
    );
  };

  const EditButton = () => {
    return (
      <MenuItem
        onClick={() => {
          if (!bookingId) return;
          router.push(`/booking/${bookingId}/review`);
        }}
      >
        Edit
      </MenuItem>
    );
  };

  const ReportButton = () => {
    return (
      <MenuItem
        onClick={() => {
          if (!auth.auth && !auth.loading) {
            setValue('name', '');
            setValue('email', '');
            setIsOpenAuthModal({ open: true, mode: 'sign-in' });
            return;
          }
          setIsOpenReportModal(true);
        }}
      >
        <Typography color="#1890FF">Report</Typography>
      </MenuItem>
    );
  };

  const DeleteButton = () => {
    return (
      <MenuItem
        onClick={() => {
          setIsOpenDeleteModal(true);
        }}
      >
        <Typography variant="body2" color="#B72136">
          Delete
        </Typography>
      </MenuItem>
    );
  };

  return (
    <>
      <Manage>
        <ButtonGroup ref={anchorRef}>
          <IconButton onClick={handleToggle}>
            <DotMenuIconSVG />
          </IconButton>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} placement="right" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement,
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {isAuthor ? (
                      <>
                        {isCourseOwner && <PinButton />}
                        <ShareButton />
                        <EditButton />
                        <DeleteButton />
                      </>
                    ) : (
                      <>
                        {isCourseOwner && <PinButton />}
                        <ShareButton />
                        <ReportButton />
                      </>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Manage>

      {/* ShareModal */}
      <Modal
        closeColor="#212B36"
        isOpen={isOpenModalShare}
        onClose={() => {
          setIsOpenModalShare(false);
        }}
      >
        <Box
          sx={{
            px: 2,
            pt: 6,
            pb: 20,
            width: isMobile ? '100%' : 444,
          }}
        >
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5">Share Your Review</Typography>
              <Typography variant="subtitle2">Let others know about your experience!</Typography>
            </Box>
            <Box sx={{ py: 4, px: 4 }}>
              <img src={shareModalImage} alt="vector" />
            </Box>
            <Typography variant="subtitle2" width="100%" textAlign="left">
              Copy this link to share
            </Typography>
            <Stack
              sx={{
                border: '1px solid #919EAB52',
                px: 1,
                py: 1,
                height: 60,
                borderRadius: 1,
                width: '100%',
              }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box sx={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center' }}>
                <LinkIconSVG />
              </Box>
              <TextMaxLine line={1} variant="body1" sx={{ ml: 1 }} color="#919EAB">
                {`${window.location.host}${shareUrl}`}
              </TextMaxLine>
              <Box
                sx={{
                  cursor: 'pointer',
                  boxShadow: '0px 8px 16px 0px rgba(7, 56, 57, 0.24)',
                  background: '#073938',
                  px: 3.5,
                  py: 1.3,
                  borderRadius: 1,
                  ml: 2,
                }}
                onClick={() => {
                  navigator.clipboard.writeText(`${window.location.host}${shareUrl}`);
                  setIsOpenCopyLinkAlert(true);
                }}
              >
                <Typography variant="button" color="#D7D3CC">
                  Copy
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Snackbar
            open={isOpenCopyLinkAlert}
            autoHideDuration={3000}
            onClose={() => {
              setIsOpenCopyLinkAlert(false);
            }}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <Alert severity="success" sx={{ width: '100%' }}>
              Link copied
            </Alert>
          </Snackbar>
        </Box>
      </Modal>

      {/* DeleteModal */}
      <Dialog
        maxWidth="xs"
        open={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(false)}
        aria-labelledby="calendar-dialog-title"
        aria-describedby="calendar-dialog-description"
        TransitionComponent={ModalTransition}
      >
        <Box sx={{ textAlign: 'center', px: 2 }}>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h4" px={1} py={3}>
                Delete this review?
              </Typography>
              <Typography variant="body1" pb={3.75}>
                Are you sure you want to do this? Once deleted this cannot be recover.
              </Typography>
            </Box>
          </Stack>
          <Stack
            py={3}
            px={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1.5}
          >
            <Button
              sx={{ minWidth: '140px' }}
              variant="outlined"
              onClick={() => {
                setIsOpenDeleteModal(false);
              }}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              sx={{ minWidth: '140px' }}
              type="submit"
              variant="contained"
              onClick={() => {
                setIsLoading(true);
                onDelete();
              }}
              color="error"
              disabled={isLoading}
            >
              Delete
            </Button>
          </Stack>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsOpenDeleteModal(false);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#212B36',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
      <Snackbar
        open={isOpenDeleteAlert}
        autoHideDuration={3000}
        onClose={() => {
          setIsOpenDeleteAlert(false);
        }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        sx={{ zIndex: 1 }}
      >
        <Alert severity="info" sx={{ width: '100%' }}>
          Your review has been deleted
        </Alert>
      </Snackbar>

      {/* ReportModal */}
      <Dialog
        maxWidth="xs"
        open={isOpenReportModal}
        onClose={() => setIsOpenReportModal(false)}
        aria-labelledby="calendar-dialog-title"
        aria-describedby="calendar-dialog-description"
        TransitionComponent={ModalTransition}
      >
        <Box sx={{ textAlign: 'center', px: 2 }}>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" px={1} pb={3} pt={7}>
                Report inappropriate content
              </Typography>
            </Box>
          </Stack>
          <form onSubmit={handleSubmit(onReport)}>
            <Grid container paddingX={1}>
              <Grid item xs={12} pt={1}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => <TextField label="Name" {...field} fullWidth disabled />}
                />
              </Grid>
              <Grid item xs={12} py={4}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => <TextField label="Email" {...field} fullWidth disabled />}
                />
              </Grid>
              <Grid item xs={12} pb={5.75}>
                <Controller
                  name="reason"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Reason"
                      multiline
                      rows={4}
                      inputProps={{ maxLength: 250 }}
                      {...field}
                      fullWidth
                      error={!!errors.reason?.message}
                      helperText={<WarningMessage message={errors.reason?.message} />}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
          <Stack
            py={3}
            px={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1.5}
          >
            <Button
              sx={{ width: '100%' }}
              type="submit"
              variant="contained"
              onClick={handleSubmit(onReport)}
              color="primary"
              disabled={isLoading}
            >
              Submit
            </Button>
          </Stack>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsOpenReportModal(false);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#212B36',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
      {/* REPORT ERROR */}
      <Snackbar
        open={isOpenReportAlertErr}
        autoHideDuration={3000}
        onClose={() => {
          setIsOpenReportAlertErr(false);
        }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        sx={{ zIndex: 1 }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          Something went wrong, please try again.
        </Alert>
      </Snackbar>
      {/* REPORT INFO */}
      <Snackbar
        open={isOpenReportAlertInfo}
        autoHideDuration={3000}
        onClose={() => {
          setIsOpenReportAlertInfo(false);
        }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        sx={{ zIndex: 999 }}
      >
        <Alert severity="info" sx={{ width: '100%' }}>
          We received your report.
        </Alert>
      </Snackbar>
    </>
  );
};

ReviewManage.defaultProps = {
  isPinned: false,
  isCourseOwner: false,
};

export default memo(ReviewManage);
