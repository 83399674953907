import { FC, memo, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import LikeIconSVG from './svg/LikeIcon';
import DislikeIconSVG from './svg/DislikeIcon';
import { like_review, dislike_review } from 'src/services/review';
import { get_access_token } from '@services/auth';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface ReviewLikeProps {
  reviewId: number;
  isLike: boolean;
  likeCount: number;
  isFullDisplay?: boolean;
  isAbsolute?: boolean;
  afterLike?: (value: number) => void;
}

const ReviewLikeButton: FC<ReviewLikeProps> = ({
  reviewId,
  isLike,
  likeCount,
  isFullDisplay,
  isAbsolute,
  afterLike,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [countingLike, setCountingLike] = useState<number>(0);
  const [wasLiked, setWasLiked] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setCountingLike(likeCount);
    setWasLiked(isLike);
  }, [likeCount, isLike]);

  const onLikeAction = (act: string) => {
    like_review(reviewId, act)
      .then((res) => {
        if (res?.code === '200') {
          setCountingLike(countingLike + 1);
          setWasLiked(true);
          afterLike && afterLike(reviewId);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // .finally(() => {
    //   setLoading(false);
    //   console.log('finally onLikeAction');
    // });
  };

  const onDislikeAction = (act: string) => {
    dislike_review(reviewId, act)
      .then((res) => {
        if (res?.code === '200') {
          setCountingLike(countingLike - 1);
          setWasLiked(false);
          afterLike && afterLike(reviewId);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onClickLike = () => {
    if (loading) return;
    setLoading(true);
    get_access_token()
      .then(async (token) => {
        if (!wasLiked) {
          onLikeAction(token);
        } else {
          onDislikeAction(token);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const absolute = isAbsolute
    ? {
        position: 'absolute',
        bottom: '5%',
        left: '3%',
      }
    : {};

  return (
    <Box
      sx={{
        ...absolute,
        display: 'inline-block',
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 9999,
        pl: isAbsolute ? 2 : 0,
        pt: 1,
        pr: 2,
        pb: 1,
        borderRadius: 1,
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: isMobile ? '' : 'scale(1.2) translateX(10%)',
        },
        '&:active': {
          transform: 'scale(1.2) translateX(10%) rotate(13deg)',
          transition: 'all 0.1s ease-in-out',
        },
      }}
      onClick={() => {
        if (loading) return;
        onClickLike();
      }}
      id={`reviewLikeButton-${reviewId}`}
    >
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
        {wasLiked ? (
          <LikeIconSVG width={!isFullDisplay ? 20 : 24} height={!isFullDisplay ? 20 : 24} />
        ) : (
          <DislikeIconSVG width={!isFullDisplay ? 20 : 24} height={!isFullDisplay ? 20 : 24} />
        )}
        {!isFullDisplay ? (
          countingLike !== 0 && (
            <Typography
              variant="subtitle2"
              color={wasLiked ? '#073938' : '#212B36'}
              fontWeight={600}
              fontSize={12}
            >
              {countingLike}
            </Typography>
          )
        ) : (
          <Typography
            variant="subtitle2"
            color={wasLiked ? '#073938' : '#212B36'}
            fontWeight={600}
            fontSize={14}
            sx={{ userSelect: 'none' }}
          >
            Helpful {countingLike ? `(${countingLike})` : ''}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

ReviewLikeButton.defaultProps = {
  isFullDisplay: true,
  isAbsolute: false,
};

export default memo(ReviewLikeButton);
