import { Alert, Snackbar } from '@mui/material';
import { FunctionComponent, useState, useEffect } from 'react';

interface ReportNotifyProps {
  type: 0 | 1 | 2; // not things | success | error
}

const ReviewLargeImageItem: FunctionComponent<ReportNotifyProps> = (props) => {
  const { type } = props;

  const [isOpenReportAlertInfo, setIsOpenReportAlertInfo] = useState<boolean>(false);
  const [isOpenReportAlertErr, setIsOpenReportAlertErr] = useState<boolean>(false);

  useEffect(() => {
    setIsOpenReportAlertInfo(type === 1);
    setIsOpenReportAlertErr(type === 2);
    setTimeout(() => {
      setIsOpenReportAlertInfo(false);
      setIsOpenReportAlertErr(false);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      {/* REPORT ERROR */}
      <Snackbar
        open={isOpenReportAlertErr}
        // autoHideDuration={3000}
        onClose={() => {
          setIsOpenReportAlertErr(false);
        }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        sx={{ zIndex: 1 }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          Something went wrong, please try again.
        </Alert>
      </Snackbar>
      {/* REPORT INFO */}
      <Snackbar
        open={isOpenReportAlertInfo}
        // autoHideDuration={3000}
        onClose={() => {
          setIsOpenReportAlertInfo(false);
        }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        sx={{ zIndex: 999 }}
      >
        <Alert severity="info" sx={{ width: '100%' }}>
          We received your report.
        </Alert>
      </Snackbar>
    </>
  );
};

ReviewLargeImageItem.defaultProps = {
  type: 0,
};

export default ReviewLargeImageItem;
