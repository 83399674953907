export function change_symbol(text: string) {
  return text
    ?.trim()
    ?.replace(/['",]/g, '')
    ?.replace(/[ &*@\\^$!/#()%+?;~_[\]:,°÷.…—€><µ×¶®§™´`ˆ฿={}|]|[\u0e3a]|[\u0e4d]/g, '-');
}

export function url_profile(id: string | number) {
  if (typeof id === 'number') return `/profile/${id}`;
  return `/profile/${change_symbol(id)}`;
}

export function url_course_menu(chefName: string, courseName: string, query?: string) {
  return `/profile/${change_symbol(chefName)}/chefs-table/${change_symbol(courseName)}${
    query ? `${query}` : ''
  }`;
}
