import { get, post, deletes } from '@utils/fetch';

export interface PeopleReview {
  total: number;
  data: DinersReviewData[];
}

export interface DinersReviewData {
  courseId: number;
  chefName: string;
  courseName: string;
  experience: string;
  id: number;
  images: [
    {
      id: number;
      imageUrl: string;
      thumbnailUrl: string;
    }
  ];
  reviewAt: string;
  tag: string[];
  user: {
    chefId: number;
    firstName: string;
    id: number;
    imageProfile: {
      id: number;
      imageUrl: string;
      thumbnailUrl: string;
    };
    isChef: boolean;
    lastName: string;
  };
  isLike: boolean;
  likeCount: number;
  bookingId: number;
}

export function get_review(page: number, limit: number, userId?: number) {
  return get<PeopleReview>(
    `${process.env.NEXT_PUBLIC_API_URL}/review?page=${page}&limit=${limit}${
      userId ? `&userId=${userId}` : ''
    }`
  );
}

export function get_review_overall(courseId: number, token: string) {
  return get<any>(
    `${process.env.NEXT_PUBLIC_API_URL}/review/course/${courseId}/overall-experience`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function pin_review(reviewId: number, token: string) {
  return await post(
    `${process.env.NEXT_PUBLIC_API_URL}/review/${reviewId}/pin`,
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function unpin_review(reviewId: number, token: string) {
  return await post(
    `${process.env.NEXT_PUBLIC_API_URL}/review/${reviewId}/unpin`,
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function like_review(reviewId: number, token: string) {
  return await post(
    `${process.env.NEXT_PUBLIC_API_URL}/review/${reviewId}/helpful`,
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export function dislike_review(reviewId: number, token: string) {
  return deletes(`${process.env.NEXT_PUBLIC_API_URL}/review/${reviewId}/helpful`, {
    Authorization: `Bearer ${token}`,
  });
}

export function delete_review(reviewId: number, token: string) {
  return deletes(`${process.env.NEXT_PUBLIC_API_URL}/review/${reviewId}`, {
    Authorization: `Bearer ${token}`,
  });
}

export async function complain_review(reviewId: number, token: string, reportReason: string) {
  return await post(
    `${process.env.NEXT_PUBLIC_API_URL}/review/${reviewId}/complain`,
    { reason: reportReason },
    {
      Authorization: `Bearer ${token}`,
    }
  );
}
