import React, { ReactElement, FC, memo } from 'react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { pxToRem } from '@utils/getFontValue';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';

interface Props {
  message?: string;
}

const WarningMessage: FC<Props> = ({ message }): ReactElement => {
  return (
    <>
      {!!message && (
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <WarningRoundedIcon sx={{ fontSize: 18 }} />
          <Typography variant="caption" paragraph sx={{ fontSize: pxToRem(12) }}>
            {message}
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default memo(WarningMessage);
